<script lang="ts">
    import { Icon, Banknotes, ChevronDown } from 'svelte-hero-icons';
    import PointLight from '@/Icons/PointLight.svelte';
    import oxxo from '../../../../public/img/logos/oxxo.svg';
    import payStack from '../../../../public/img/logos/paystack.svg';
    import stripe from '../../../../public/img/logos/stripe.svg';
    import paypal from '../../../../public/img/logos/paypal.svg';
    import coinbase from '../../../../public/img/logos/coinbase.svg';
    import nowpayments from '../../../../public/img/logos/nowpayments.svg';
    import ccbill from '../../../../public/img/logos/ccbill.svg';
    import PointLights from '../../../../public/svg/points_light.svg';
    import PointDark from '../../../../public/svg/points_dark.svg';

    export let paymentSettings;
</script>

<div class="row checkout-dialog">
    <div class="col-lg-6 mx-auto">
        <div class="paymentOption paymentPP d-none">
            <form id="pp-buyItem">
                <input type="hidden" name="amount" id="payment-deposit-amount" value="" />

                <input type="hidden" name="transaction_type" id="payment-type" value="" />

                <input type="hidden" name="post_id" id="post" value="" />

                <input type="hidden" name="user_message_id" id="userMessage" value="" />

                <input type="hidden" name="recipient_user_id" id="recipient" value="" />

                <input type="hidden" name="provider" id="provider" value="" />

                <input type="hidden" name="first_name" id="paymentFirstName" value="" />

                <input type="hidden" name="last_name" id="paymentLastName" value="" />

                <input type="hidden" name="billing_address" id="paymentBillingAddress" value="" />

                <input type="hidden" name="city" id="paymentCity" value="" />

                <input type="hidden" name="state" id="paymentState" value="" />

                <input type="hidden" name="postcode" id="paymentPostcode" value="" />

                <input type="hidden" name="country" id="paymentCountry" value="" />

                <input type="hidden" name="taxes" id="paymentTaxes" value="" />

                <input type="hidden" name="stream" id="stream" value="" />

                <button class="payment-button" type="submit"></button>
            </form>
        </div>
        <div class="paymentOption paymentStripe d-none ml-2">
            <button id="stripe-checkout-button">{'Checkout'}</button>
        </div>
        <!-- Modal -->
        <div
            class="checkout-popup modal fade"
            id="checkout-center"
            tabindex="-1"
            role="dialog"
            aria-labelledby="checkout"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="payment-title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="{'Close'}">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="payment-body">
                            <div class="d-flex flex-row">
                                <div class="ml-md-2 mb-2 ml-0">
                                    <img src="" class="rounded-circle user-avatar" alt="" />
                                </div>
                                <div class="d-lg-block">
                                    <div class="d-flex justify-content-center flex-column pl-2">
                                        <div class="ml-2">
                                            <div
                                                class="font-bold {paymentSettings.appTheme == null
                                                    ? paymentSettings.defaultUserTheme == 'dark'
                                                        ? ''
                                                        : 'text-dark-r'
                                                    : paymentSettings.appTheme == 'dark'
                                                      ? ''
                                                      : 'text-dark-r'} name"
                                            ></div>
                                            <div class="username text-muted"><span>@</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="payment-description d-none mb-3"></div>
                            <div class="input-group checkout-amount-input d-none mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="amount-label">
                                        <Icon src="{Banknotes}" size="24px" />
                                    </span>
                                </div>
                                <input
                                    class="form-control uifield-amount"
                                    placeholder="{`Amount (${paymentSettings.minimumTimValue} P min, ${paymentSettings.maximumTipValue} P max)`}"
                                    aria-label="Username"
                                    aria-describedby="amount-label"
                                    id="checkout-amount"
                                    type="number"
                                    min="5"
                                    step="1"
                                    max="2000"
                                />
                                <div class="invalid-feedback">{'Please enter a valid amount.'}</div>
                            </div>
                        </div>

                        <div id="accordion" class="mb-3">
                            <div class="card">
                                <div
                                    class="card-header d-flex justify-content-between"
                                    id="headingOne"
                                    data-toggle="collapse"
                                    data-target="#billingInformation"
                                    aria-expanded="true"
                                    aria-controls="billingInformation"
                                >
                                    <h6 class="mb-0">
                                        {'Billing agreement details'}
                                    </h6>
                                    <div class="label-icon ml-1">
                                        <Icon src="{ChevronDown}" size="24px" />
                                    </div>
                                </div>
                                <div
                                    id="billingInformation"
                                    class="show collapse"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div class="card-body">
                                        <form id="billing-agreement-form">
                                            <div class="tab-content">
                                                <div id="individual" class="tab-pane fade show active pt-1">
                                                    <div class="row form-group">
                                                        <div class="col-sm-6 col-6">
                                                            <div class="form-group">
                                                                <label for="firstName">
                                                                    <span>{'First name'}</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="firstName"
                                                                    placeholder="{'First name'}"
                                                                    onchange="checkout.validateFirstNameField();"
                                                                    required
                                                                    class="form-control uifield-first_name"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-6">
                                                            <div class="form-group">
                                                                <label for="lastName">
                                                                    <span>{'Last name'}</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    placeholder="{'Last name'}"
                                                                    onblur="checkout.validateLastNameField()"
                                                                    required
                                                                    class="form-control uifield-last_name"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="countrySelect">
                                                            <span>{'Country'}</span>
                                                        </label>
                                                        <select
                                                            class="country-select form-control input-sm uifield-country"
                                                            id="countrySelect"
                                                            required
                                                            onchange="checkout.validateCountryField()"
                                                        ></select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="billingCity">
                                                            <span>{'City'}</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="billingCity"
                                                            placeholder="{'City'}"
                                                            onblur="checkout.validateCityField()"
                                                            required
                                                            class="form-control uifield-city"
                                                        />
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6 col-6">
                                                            <div class="form-group">
                                                                <label for="billingState">
                                                                    <span>{'State'}</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="billingState"
                                                                    placeholder="{'State'}"
                                                                    onblur="checkout.validateStateField()"
                                                                    required
                                                                    class="form-control uifield-state"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-6">
                                                            <div class="form-group">
                                                                <label for="billingPostcode">
                                                                    <span>{'Postcode'}</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="billingPostcode"
                                                                    placeholder="{'Postcode'}"
                                                                    onblur="checkout.validatePostcodeField()"
                                                                    required
                                                                    class="form-control uifield-postcode"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="cardNumber">
                                                            <span>{'Address'}</span>
                                                        </label>
                                                        <textarea
                                                            rows="2"
                                                            type="text"
                                                            name="billingAddress"
                                                            onblur="checkout.validateBillingAddressField()"
                                                            placeholder="{'Street address, apartment, suite, unit'}"
                                                            class="form-control w-100 uifield-billing_address"
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div class="billing-agreement-error error text-danger d-none">
                                                    {'Please complete all billing details'}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <h6>{'Payment summary'}</h6>
                            <div class="subtotal row">
                                <span class="col-sm left"><b>{'Subtotal'}:</b></span>
                                <span class="subtotal-amount col-sm right text-right">
                                    <b class="flex items-center justify-end gap-1">
                                        <span>0.00</span>

                                        <img
                                            src="{paymentSettings.appTheme === 'dark' ? PointDark : PointLights}"
                                            alt="points"
                                            srcset=""
                                        />
                                    </b>
                                </span>
                            </div>
                            <div class="taxes row hidden">
                                <span class="col-sm left"><b>{'Taxes'}:</b></span>
                                <span class="country-taxes col-sm right text-right">
                                    <b class="flex items-center justify-end gap-1"
                                        ><span>0.00</span>

                                        <img
                                            src="{paymentSettings.appTheme === 'dark' ? PointDark : PointLights}"
                                            alt="points"
                                            srcset=""
                                        />
                                    </b>
                                </span>
                            </div>
                            <div class="taxes-details"></div>
                            <div class="total row">
                                <span class="col-sm left"><b>{'Total'}:</b></span>
                                <span class="total-amount col-sm right text-right">
                                    <b class="flex items-center justify-end gap-1"
                                        ><span>0.00</span>

                                        <img
                                            src="{paymentSettings.appTheme === 'dark' ? PointDark : PointLights}"
                                            alt="points"
                                            srcset=""
                                        />
                                    </b>
                                </span>
                            </div>
                        </div>

                        <div>
                            <h6>{'Payment method'}</h6>
                            <div class="d-flex radio-group row px-3 text-left">
                                {#if paymentSettings.stripeSecretKey && paymentSettings.stripePublicKey && !paymentSettings.isStripeCheckoutDisabled}
                                    <div class="col-6 col-md-3 stripe-payment-method p-0">
                                        <div
                                            class="radio stripe-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="stripe"
                                        >
                                            <img src="{stripe}" alt="stripe" srcset="" />
                                        </div>
                                    </div>
                                {/if}
                                {#if paymentSettings.paypalClientId && paymentSettings.paypalSecret && !paymentSettings.isPaypalCheckoutDisabled}
                                    <div class="col-6 col-md-3 paypal-payment-method p-0">
                                        <div
                                            class="radio paypal-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="paypal"
                                        >
                                            <img src="{paypal}" alt="paypal" srcset="" />
                                        </div>
                                    </div>
                                {/if}
                                {#if paymentSettings.coinbaseApiKey && !paymentSettings.isCoinbaseCheckoutDisabled}
                                    <div class="col-6 col-md-3 d-none coinbase-payment-method p-0">
                                        <div
                                            class="radio coinbase-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="coinbase"
                                        >
                                            <img src="{coinbase}" alt="coinbase" srcset="" />
                                        </div>
                                    </div>
                                {/if}
                                {#if paymentSettings.nowPaymentApiKey && !paymentSettings.isNowPaymentsCheckoutDisabled}
                                    <div class="col-6 col-md-3 d-none nowpayments-payment-method p-0">
                                        <div
                                            class="radio nowpayments-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="nowpayments"
                                        >
                                            <img src="{nowpayments}" alt="nowpayments" srcset="" />
                                        </div>
                                    </div>
                                {/if}
                                {#if paymentSettings.isCCbillCredentialsProvided}
                                    <div class="col-6 col-md-3 d-none ccbill-payment-method p-0">
                                        <div
                                            class="radio ccbill-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="ccbill"
                                        >
                                            <img src="{ccbill}" alt="ccbil" srcset="" />
                                        </div>
                                    </div>
                                {/if}
                                {#if paymentSettings.paystackSecretKey && !paymentSettings.isPaystackCheckoutDisabled}
                                    <div class="col-6 col-md-3 d-none paystack-payment-method p-0">
                                        <div
                                            class="radio paystack-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="paystack"
                                        >
                                            <img src="{payStack}" alt="Pay stack" srcset="" />
                                        </div>
                                    </div>
                                {/if}
                                {#if paymentSettings.stripeSecretKey && paymentSettings.stripePublicKey && !paymentSettings.isStripeCheckoutDisabled && paymentSettings.isStripeOXXOProviderEnabled}
                                    <div class="col-6 col-md-3 d-none oxxo-payment-method p-0">
                                        <div
                                            class="radio oxxo-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto"
                                            data-value="oxxo"
                                        >
                                            <img src="{oxxo}" alt="oxxo" />
                                        </div>
                                    </div>
                                {/if}
                                <div
                                    class="col-6 col-md-4 p-0"
                                    data-toggle="{paymentSettings.walletTotal ? 'tooltip' : ''}"
                                    data-placement="{paymentSettings.walletTotal ? 'right' : ''}"
                                    title="{paymentSettings.walletTotal
                                        ? 'You can use the wallet deposit page to add credit.'
                                        : ''}"
                                >
                                    <div
                                        class="radio credit-payment-provider checkout-payment-provider d-flex align-items-center justify-content-center mx-auto w-full"
                                        data-value="credit"
                                    >
                                        <div class="credit-provider-text flex gap-2">
                                            <b>{'Credit'}</b>
                                            <div class="available-credit flex items-center justify-between gap-1">
                                                <span>0</span>

                                                <img src="{PointLight}" alt="Point_Light" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment-error error text-danger d-none mb-1 font-bold">
                            {'Please select your payment method'}
                        </div>
                        <p class="mt-1 text-muted">
                            Note: After clicking on the button, you will be directed to a secure gateway for payment.
                            After completing the payment process, you will be redirected back to the website.
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-primary checkout-continue-btn"
                            >Continue
                            <div class="spinner-border spinner-border-sm d-none ml-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
